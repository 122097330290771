import { Component, OnInit, Signal } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { IconService, LoadingModule, NotificationModule, ThemeType } from 'carbon-components-angular';
import User20 from '@carbon/icons/es/user/20';
import Settings20 from '@carbon/icons/es/settings/20';
import Settings16 from '@carbon/icons/es/settings/16';
import Location16 from '@carbon/icons/es/location/16';
import TableSplit16 from '@carbon/icons/es/table--split/16';
import ResultDraft16 from '@carbon/icons/es/result--draft/16';
import ModelBuilder16 from '@carbon/icons/es/model-builder/16';
import Folders16 from '@carbon/icons/es/folders/16';
import InformationFilled20 from '@carbon/icons/es/information--filled/20';
import CheckmarkFilled20 from '@carbon/icons/es/checkmark--filled/20';
import ErrorFilled20 from '@carbon/icons/es/error--filled/20';
import Logout20 from '@carbon/icons/es/logout/20';
import CloseIcon16 from '@carbon/icons/es/close/16';
import Namespace16 from '@carbon/icons/es/name-space/16';
import Analytics16 from '@carbon/icons/es/analytics/16';
import Diagram16 from '@carbon/icons/es/diagram/16';
import WarningFilled20 from '@carbon/icons/es/warning--filled/20';
import ChartPie16 from '@carbon/icons/es/chart--pie/16';
import Export16 from '@carbon/icons/es/export/16';
import { CommonModule } from '@angular/common';
import { CarbonModule } from '../../theme/carbon.module';
import { SelfUnsubscribeComponent } from '../../shared/components/self-unsubscribe/self-unsubscribe.component';
import { filter, map, takeUntil } from 'rxjs';
import { UserBasicInfo } from '../../features/authentication/models/UserBasicInfo';
import { UserRole } from '../../features/authentication/models/UserRole';
import {
  ADMIN_MENU_ITEM,
  DRAWER_THEME,
  LAYOUT_THEME,
  USER_ROLE_MAP,
  getDashboardMenuItem,
} from '../../shared/constants';
import { MenuItem } from '../../shared/models';
import { AuthService } from '../../features/authentication/services/auth.service';
import { LoadingService } from '../../shared/services';
import { MenuItemsSignal } from '../../shared/signals';

@Component({
  selector: 'neom-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, CarbonModule, NotificationModule, LoadingModule],
  providers: [IconService],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent extends SelfUnsubscribeComponent implements OnInit {
  layoutTheme: ThemeType = LAYOUT_THEME;
  drawerTheme: ThemeType = DRAWER_THEME;

  secondAction: boolean = false;
  isActiveSwitcher: boolean = false;
  sidenavExpanded: boolean = false;
  hamburger: boolean = false;
  hasActiveChild: boolean = false;

  user: UserBasicInfo | null = null;
  userRoleMap: Map<UserRole, string> = USER_ROLE_MAP;

  loaderActive = false;

  get sideMenuItems(): Signal<MenuItem[]> {
    return this._menuItemsSignal.stateSignal$;
  }

  constructor(
    protected iconService: IconService,
    private _authService: AuthService,
    private _menuItemsSignal: MenuItemsSignal,
    private _loadingService: LoadingService,
    private _router: Router,
  ) {
    super();
    this.iconService.registerAll([
      User20,
      Settings20,
      Settings16,
      Location16,
      TableSplit16,
      ResultDraft16,
      ModelBuilder16,
      Folders16,
      CloseIcon16,
      InformationFilled20,
      CheckmarkFilled20,
      ErrorFilled20,
      Logout20,
      Namespace16,
      Analytics16,
      Diagram16,
      WarningFilled20,
      ChartPie16,
      Export16
    ]);
    this._router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((event) => event instanceof NavigationStart),
        map((event) => event as NavigationStart),
        filter((navigationStart) => !navigationStart.url.includes('project-management')),
      )
      .subscribe({
        next: () => {
          const menuItems = this.sideMenuItems().filter((item) => item.order !== getDashboardMenuItem().order);
          menuItems.sort((a, b) => a.order - b.order);
          this._menuItemsSignal.updateMenuItems(menuItems);
        },
      });

    this._loadingService.isLoading$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (value) => {
        this.loaderActive = value;
      },
    });
  }

  ngOnInit(): void {
    this.user = this._authService.authenticatedUser
      ? this._authService.authenticatedUser
      : localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')!)
      : null;

    this._updateAdminMenuItem();
  }

  logout(): void {
    this._authService.logout();//.pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }

  sideMenuTrackBy(index: number, sideMenuItem: MenuItem): number {
    return sideMenuItem.order;
  }

  private _updateAdminMenuItem() {
    const menuItems = this.sideMenuItems();
    if (this.user?.userRole === UserRole.Administrator && menuItems.every((item) => item !== ADMIN_MENU_ITEM)) {
      menuItems.push(ADMIN_MENU_ITEM);
      menuItems.sort((a, b) => a.order - b.order);
      this._menuItemsSignal.updateMenuItems(menuItems);
    }
  }
}
