import { AuthService } from './../../features/authentication/services/auth.service';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserRole } from '../../features/authentication/models/UserRole';

export const authenticationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  // Inject needed services
  const router = inject(Router);
  const authService = inject(AuthService);

  // Get the user roles needed for the current component + the authenticated user
  const authorizedUserRoles: (UserRole | undefined)[] = route.data['authorizedUserTypes'];
  const user = authService.authenticatedUser
    ? authService.authenticatedUser
    : localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')!)
      : null;

  // If the user is authenticated, continue the checking
  if (user != null) {
    // No role is needed, the only necessary rule is that the user is authenticated
    if (authorizedUserRoles == null || authorizedUserRoles.length == 0) {
      return true;
    }

    // Check that the user has the needed role
    var isAuthorized = authorizedUserRoles.includes(user?.userRole);

    // TODO: Navigate the user to a unauthorized page or any page
    if (!isAuthorized) {
      router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }

    return isAuthorized;
  }

  // In case return url is needed, otherwise it can be removed
  router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  return false;
};
