import {
  HttpInterceptorFn
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../../features/authentication/services/auth.service';
import { catchError, from, switchMap, throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);

  return from(authService.initialize()).pipe(
    switchMap(() => {
      return authService.getAccessToken().pipe(
        switchMap((token) => {
          if (token) {
            request = request.clone({
              headers: request.headers.set('Authorization', `Bearer ${token}`),
            });
          }
          return next(request);
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
    }),
  );
};
